/**
 * @author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2018-04-05
 * @description a reference to all components which can be installed.
 */
import { Footer } from "./page-framework/footer";
import { Navbar } from "./page-framework/nav-bar";
import { CarouselPanelContainer } from "./panels/carousel-panel";
import { PitchPanel } from "./panels/pitch-panel";
import { VerticalVideoPanel } from "./panels/vertical-video-panel";

import { OriginalShowsContainer } from "./panels/original-shows";
import { HeroCarouselContainer } from "./carousels/hero-carousel";
import { TickerTape } from "./page-framework/ticker-tape";
import { LiveVideoPanel } from "./panels/live-panel";
// import { Announcement } from "./modals/announcement";
// import { EventPanel } from "./panels/event-panel";
import { EventPanel } from "../modules/events";
import { MarketPlacePanel } from "./panels/marketplace-panel";
import { PartnersList } from "./panels/partners-list";
import { GettingStarted } from "./inner-pages/getting-started";
import { SignupPage } from "./inner-pages/signup-page";
import { SponsorPanel } from "./panels/sponsor-panel";
/**
 * The collection of all components which can be instantiated
 *
 * @type {Array<RelayContainer<*>>}
 */
export const MAIN_COMPONENTS: {
  [index: string]: any;
} = {
  VideoPanel: {
    component: LiveVideoPanel,
  },
  GettingStarted: {
    component: GettingStarted,
  },
  SignupPage: {
    component: SignupPage,
  },
  PartnersPanel: {
    component: PartnersList,
  },
  SponsorPanel: {
    component: SponsorPanel,
  },
  // Announcement: {
  //   component: Announcement,
  // },
  EventPanel: {
    component: EventPanel,
    props: {},
  },
  MarketPlacePanel: {
    component: MarketPlacePanel,
    props: {},
  },
  HeroCarousel: {
    component: HeroCarouselContainer,
    props: {},
  },
  Navbar: {
    component: Navbar,
    props: {
      transparent: false,
    },
  },
  LoggedInNavbar: {
    component: Navbar,
    props: {
      transparent: false,
    },
  },
  TransparentLoggedInNavbar: {
    component: Navbar,
    props: {
      transparent: true,
    },
  },
  Footer: {
    component: Footer,
  },
  MixedCarousel: {
    component: CarouselPanelContainer,
    props: {},
  },
  CarouselPanel: {
    component: CarouselPanelContainer,
    props: {},
  },
  PitchPanel: {
    component: PitchPanel,
    props: {},
  },
  VerticalVideoPanel: {
    component: VerticalVideoPanel,
    props: {},
  },
  Shows: {
    component: OriginalShowsContainer,
    props: {},
  },
  TransparentNavbar: {
    component: Navbar,
    props: {
      transparent: true,
    },
  },
  TickerTape: {
    component: TickerTape,
  },
};
